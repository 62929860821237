import React, { useState } from 'react'
import { Block } from '../../../components/Block'
import { Icon } from '../../../components/Icon/Icon'
import { Stack } from '../../../components/Stack'
import { Button } from '../../../components/Button/Button'
import { CitaPreviaModal } from '../../CitaPreviaModal'
import ClockSvg from '../../../images/icon/clock.svg'
import { Text } from '../../../components/Text'
import { Title } from './Title'
import styled, { css } from 'styled-components/macro'
import { sizes, color, grid } from '../../../theme'
import { media } from '../../../theme/mixins/media'
import { rem } from 'polished'
import homeBgUrl from '../../../images/home-bg.jpg'
import homeBgUrlChristmasJpg from '../../../images/christmas/home-christmas.jpg'
import homeBgUrlChristmasWebp from '../../../images/christmas/home-christmas.webp'
import HorarioMamaPng from '../../../images/maternidad/apertura-agenda.png'
import { MobileHeaderBar } from '../MobileHeaderBar'
import { Link } from '../../../components/Link'
import { config } from '../../../config'

const TitleWrapper = styled(Block)`
  padding-left: ${rem(grid.default.gap)};
  padding-right: ${rem(grid.default.gap)};
`

export const MainHeroMobile = () => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <Wrapper>
      <MobileHeaderBar />

      <TitleWrapper marginTop="size4" marginBottom="size7">
        <Title>
          Centro de Fisioterapia <br /> & Osteopatía
        </Title>
      </TitleWrapper>

      <Block
        flex
        justify="center"
        align="center"
        marginBottom="size6"
        paddingLeft="size3"
        paddingRight="size3"
      >
        <Icon size="medium" component={<ClockSvg />} marginRight="size3" />
        {/* <img
          src={HorarioMamaPng}
          style={{ marginLeft: '24px', maxWidth: '250px' }}
        /> */}
        <Text size="base" weight="light">
          De Lunes a Viernes: 8h a 20h
        </Text>
      </Block>

      <Covid>
        <Text marginBottom="size3">Nuestras medidas contra la COVID19</Text>
        <Link to="/medidas-seguridad-covid-19">MÁS INFORMACIÓN</Link>
      </Covid>

      <Block flex justify="center" paddingBottom="size5">
        <Button primary onClick={() => setModalOpen(true)}>
          Cita previa
        </Button>
      </Block>

      <CitaPreviaModal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
      />
    </Wrapper>
  )
}

const Covid = styled.div`
  padding: ${rem(sizes.size4)};
  margin-bottom: ${rem(sizes.size7)};

  margin-left: auto;
  margin-right: auto;

  background-color: #f2f2f2a3;
  text-align: center;
  max-width: 45rem;
`

const Wrapper = styled.div`
  min-height: calc(100vh);
  max-height: ${rem(600)};

  background-image: url(${homeBgUrl});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;

  ${config.enableChristmasTheme &&
    css`
      background-size: auto 100%;
      background-image: url(${homeBgUrlChristmasJpg});
      .webp & {
        background-image: url(${homeBgUrlChristmasWebp});
      }
    `}

  ${media.tablet`
    display: none;
  `}
`
