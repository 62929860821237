import React from 'react'
import ReactModal from 'react-modal'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { sizes, color } from '../theme'
import { media } from '../theme/mixins/media'
import rem from 'polished/lib/helpers/rem'
import mix from 'polished/lib/color/mix'
import { Text } from '../components/Text'
import { Separator } from '../components/Separator'
import { Stack } from '../components/Stack'
import { Icon } from '../components/Icon/Icon'
import WhatsappSvg from '../images/icon/whatsapp.svg'
import EmailSvg from '../images/icon/email.svg'
import CrossSvg from '../images/icon/cross.svg'
import { Block } from '../components/Block'
import LocationSvg from '../images/icon/location.svg'
import { Link } from '../components/Link'
import { ContactLink } from './ContactLink'

interface Props {
  isOpen: boolean
  onRequestClose: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => void
}

export const CitaPreviaModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
}) => {
  return (
    <>
      <ReactModal
        ariaHideApp={false}
        onRequestClose={onRequestClose}
        isOpen={isOpen}
        className="modal"
        overlayClassName="overlay"
      >
        <ModalStyles />
        <CloseButton onClick={onRequestClose}>
          <Icon size="medium" component={<CrossSvg />} color="turquoise" />
        </CloseButton>

        <Text size="h2" weight="light" uppercase center>
          Pide tu cita previa
        </Text>

        <Separator />

        <Block
          marginBottom="size5"
          tablet={{ marginBottom: 'size7', marginTop: 'size7' }}
          flex
          justify="center"
        >
          <Stack
            horizontal="size5"
            tablet={{ horizontal: 'size7' }}
            justify="center"
          >
            <ContactLink href="https://wa.me/34699762837" target="_blank">
              <Icon
                size="medium"
                tablet={{ size: 'large' }}
                marginBottom="size2"
                component={<WhatsappSvg />}
              />
              <Text
                tag="span"
                size="base"
                tablet={{ size: 'large' }}
                weight="bold"
              >
                699 762 837
              </Text>
            </ContactLink>

            <ContactLink as={Link} to="/contacto">
              <Icon
                size="medium"
                tablet={{ size: 'large' }}
                marginBottom="size2"
                component={<EmailSvg />}
              />
              <Text
                tag="span"
                size="base"
                tablet={{ size: 'large' }}
                weight="bold"
              >
                Escríbeme
              </Text>
            </ContactLink>
          </Stack>
        </Block>

        <Text
          size="base"
          weight="light"
          marginLeft="size8"
          marginBottom="size5"
        >
          De Lunes a Viernes: 8h a 20h
        </Text>

        <Block flex align="center" justify="center">
          <Link
            component="a"
            href="https://goo.gl/maps/VeWQJ4q5dwJUVvFT8"
            target="_blank"
          >
            <Icon
              size="tiny"
              component={<LocationSvg />}
              marginRight="size2"
              color="black"
            />
            <Text as="span">Calle Abejeras, 33 bajo, Pamplona</Text>
          </Link>
        </Block>
      </ReactModal>
    </>
  )
}

const ModalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: flex-start;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.90);
    z-index: 100;
  }

  .ReactModal__Content {
    position: relative;
    padding: ${rem(sizes.size7)} ${rem(sizes.size5)};
    overflow: hidden;
    max-width: 90vw;

    background-color: ${color.white};
    border-radius: 24px;
    outline: 0;
    margin: auto;


    ${media.tablet`
      max-width: auto;
      padding: ${rem(sizes.size9)};
    `}
  }
`

const CloseButton = styled.div`
  display: inline-flex;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 10px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    path {
      fill: ${mix(0.2, color.black, color.turquoise)};
    }
  }

  ${media.tablet`
    right: 20px;
    top: 20px;
  `}
`
